import { getTranslation } from 'utils/DictionaryUtils';

import globalConfig from 'config/globalConfig.js'

export default function ContactRow(props) {
    return (
        <div className='relative w-full overflow-hidden'>

            <div className='w-[1000px] sm:w-full -ml-[270px] sm:ml-0 aspect-[1905/1316] bg-cover' style={{ backgroundImage: 'url(assets/images/sites/www/contact/illustration.png)' }} />
            {/* <img className='w-[1920px]' src='assets/images/sites/www/contact/illustration.png' alt='' /> */}

            <div className='absolute w-full h-full top-2 lg:top-8 xl:top-16 2xl:top-32 site-px'>
                <div className='flex flex-col gap-4 xl:gap-6 items-center justify-center text-center'>
                    <div className='text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-extrabold uppercasee pb-2 text-white opacity-75'>{getTranslation('www', 'contact-title')}</div>
                    <div className='text-2xl lg:text-2xl 2xl:text-3xl font-extrabold text-slate-900'>{getTranslation('www', 'contact-content-pre')} <Link href={'mailto:' + globalConfig.contact.email} label={globalConfig.contact.email} /> {getTranslation('www', 'contact-content-post')} <Link href={'tel:' + globalConfig.contact.phone} label={globalConfig.contact.phone} />.</div>
                </div>
            </div>

        </div>
    );
}

function Link(props) {
    return (
        <a className='text-white transition-all hover:underline' href={props.href}>{props.label}</a>
    );
}