const dictionaryConfig = {
    'common': {
        'contact': {
            'hu-HU': 'Kapcsolat',
            'en-GB': 'Contact'
        },
        'brands': {
            'hu-HU': 'Creo',
            'en-GB': 'Brands'
        },
        'language': {
            'hu-HU': 'Nyelvek',
            'en-GB': 'Languages'
        },
        'email-address': {
            'hu-HU': 'E-mail cím',
            'en-GB': 'E-mail address'
        },
        'phone-number': {
            'hu-HU': 'Telefonszám',
            'en-GB': 'Phone number'
        }
    },
    'www': {
        'hero-title': {
            'hu-HU': 'Kik vagyunk?',
            'en-GB': 'Who are we?'
        },
        'hero-content': {
            'hu-HU': 'A Creo Group szoft­ver­fej­lesz­tő cég. Erős mes­ter­sé­ges in­tel­li­gen­ci­a kom­pe­ten­ci­á­val ren­del­ke­zünk és ak­tí­vak va­gyunk a medtech szek­tor­ban is. A­lap­ve­tő­en üz­le­ti szoft­ve­rek fej­lesz­té­sé­vel fog­lal­ko­zunk, szá­mos nagy névv­el a meg­ren­de­lői port­fó­li­ón­kban. Nagy gya­kor­la­tunk van kom­plex üz­le­ti fo­lya­ma­tok di­gi­ta­li­zá­lá­sá­ban és egy­sze­rű­sí­té­sé­ben. Szin­tén ajánl­juk web­fej­lesz­tő szol­gál­ta­tá­sun­kat, a­mely­nek ke­re­té­ben ma­gas mi­nő­sé­gű rend­sze­re­ket ké­szí­tünk. A mo­bil fej­lesz­tés ma már el­en­ged­he­tet­len. Android és iOS kör­nye­zet­ben van gya­kor­la­tunk.',
            'en-GB': 'Creo Group is a software developer company with a strong competence in machine learning and a special interest in the medtech sector. We have our roots in business software development with big names in our portfolio. Regarding software development, we are trained to cover complex business workflows and are designated to simplify them. We also offer our web development services to deliver fancy and high quality browser based systems. Smartphone development is a must have, we cover the main mobile platforms.'
        },
        'slider-title': {
            'hu-HU': 'Miben vagyunk nagyon jók?',
            'en-GB': 'What are we very good at?'
        },
        'slider-sub-title': {
            'hu-HU': 'Találd meg a neked megfelelő szolgáltatást! Kombinálhatod is őket! 😉',
            'en-GB': 'Find a service that fits your needs. You can also combine them! 😉'
        },
        'client-title': {
            'hu-HU': 'Ügyfeleink',
            'en-GB': 'Our clients'
        },
        'client-sub-title': {
            'hu-HU': 'Akikre nagyon büszkék vagyunk',
            'en-GB': 'We are very proud at'
        },
        'contact-title': {
            'hu-HU': 'Írj nekünk!',
            'en-GB': 'Say Hello!'
        },
        'contact-content-pre': {
            'hu-HU': 'Írj nekünk az',
            'en-GB': 'Write to'
        },
        'contact-content-post': {
            'hu-HU': 'címre, vagy hívj minket:',
            'en-GB': 'or call'
        }
    }
}

export default dictionaryConfig;