const globalConfig = {
    'etc': {
        'cookiePrefix': 'CreoWebsite_',
    },
    'contact': {
        'name': 'creo.hu Kft.',
        'address': '1088 Budapest, Rákóczi út 19. 3/1a',
        'phone': '+36 1 338 1739',
        'email': 'info@creo.hu',
        'web': 'www.creo.hu'
    },
    'languageConfig': {
        'hu-HU': { 'label': 'Magyar', 'shartLabel': 'HU', 'icon': 'assets/images/languages/hu-HU.png' },
        'en-GB': { 'label': 'English', 'shartLabel': 'EN', 'icon': 'assets/images/languages/en-GB.png' },
        'de-DE': { 'label': 'Deutsch', 'shartLabel': 'DE', 'icon': 'assets/images/languages/de-DE.png' },
    },
    'brandList': [
        { 'label': 'Creo Sotware', 'url': 'https://software.creo.hu/', 'image': 'assets/images/brands/software.png' },
        { 'label': 'Creo Web', 'url': 'https://web.creo.hu/', 'image': 'assets/images/brands/web.png' },
        { 'label': 'Creo Mobile', 'url': 'https://mobile.creo.hu/', 'image': 'assets/images/brands/mobile.png' },
        { 'label': 'PartnerMail', 'url': 'https://partnermail.eu/', 'image': 'assets/images/brands/partnermail.png' },
        { 'label': 'Creo Group', 'url': 'https://creo.hu/', 'image': 'assets/images/brands/main.png' },
        { 'label': 'HelloCaptcha', 'url': 'http://www.hellocaptcha.com/', 'image': 'assets/images/brands/hellocaptcha.png' },
        { 'label': 'Creo Recommend', 'url': 'https://recommender-system.com/', 'image': 'assets/images/brands/reco.png' },
        { 'label': 'ShopEngager', 'url': 'http://shopengager.com/', 'image': 'assets/images/brands/shopengager.png' },
        { 'label': 'HomeSensor', 'url': 'http://home-sensor.com/', 'image': 'assets/images/brands/homesensor.png' },
        { 'label': 'silent.place', 'url': 'https://silent.place/', 'image': 'assets/images/brands/silentplace.png' },
        { 'label': 'Creo Medical', 'url': 'https://medical.creo.hu/', 'image': 'assets/images/brands/medical.png' },
        { 'label': 'Creo Know', 'url': 'https://know.creo.hu/', 'image': 'assets/images/brands/know.png' }
    ],
    'referencesList': [
        { 'image': 'assets/images/clients/austrian_parliament.png', 'label': { 'hu-HU': 'Osztrák Parlament', 'en-GB': 'Austrian Parliament' }, 'urlLabel': 'www.parlament.gv.at', 'url': 'http://www.parlament.gv.at/' },
        { 'image': 'assets/images/clients/medicor.png', 'label': { 'hu-HU': 'Medicor', 'en-GB': 'Medicor' }, 'urlLabel': 'www.medicor.hu', 'url': 'http://www.medicor.hu/' },
        { 'image': 'assets/images/clients/elmu.png', 'label': { 'hu-HU': 'ELMŰ Nyrt.', 'en-GB': 'ELMŰ Nyrt.' }, 'urlLabel': 'www.elmu.hu', 'url': 'http://www.elmu.hu/' },
        { 'image': 'assets/images/clients/emasz.png', 'label': { 'hu-HU': 'ÉMÁSZ Nyrt.', 'en-GB': 'ÉMÁSZ Nyrt.' }, 'urlLabel': 'www.emasz.hu', 'url': 'http://www.emasz.hu/' },
        { 'image': 'assets/images/clients/europass.png', 'label': { 'hu-HU': 'Nemzeti Referencia Központ', 'en-GB': 'National Reference Point' }, 'urlLabel': 'nrk.nive.hu', 'url': 'http://nrk.nive.hu/nyit_lap' },
        { 'image': 'assets/images/clients/asklepios.png', 'label': { 'hu-HU': 'Asklepios Kampusz Hamburg', 'en-GB': 'Asklepios Campus Hamburg' }, 'urlLabel': 'www.asklepios.hu', 'url': 'http://www.asklepios.com/' },
        { 'image': 'assets/images/clients/elte.png', 'label': { 'hu-HU': 'ELTE', 'en-GB': 'Eötvös Loránd University' }, 'urlLabel': 'www.elte.hu', 'url': 'http://www.elte.hu/' },
        { 'image': 'assets/images/clients/bme.png', 'label': { 'hu-HU': 'BME', 'en-GB': 'Budapest University of Technology and Economics' }, 'urlLabel': 'www.bme.hu', 'url': 'http://www.bme.hu/' },
        { 'image': 'assets/images/clients/sote.png', 'label': { 'hu-HU': 'SOTE', 'en-GB': 'Semmelweis University' }, 'urlLabel': 'www.semmelweis.hu', 'url': 'http://semmelweis.hu/' },
        { 'image': 'assets/images/clients/rwe.png', 'label': { 'hu-HU': 'E-Mobility', 'en-GB': 'E-Mobility' }, 'urlLabel': 'e-autozas.hu', 'url': 'https://e-autozas.hu/' },
        { 'image': 'assets/images/clients/pknyp.png', 'label': { 'hu-HU': 'Postás Kiegészítő Nyugdíjpénztár', 'en-GB': 'Post Supplementary Pension Fund' }, 'urlLabel': 'www.postas-onkentesnyugdij.hu', 'url': 'https://www.postas-onkentesnyugdij.hu/' },
        { 'image': 'assets/images/clients/bpb.png', 'label': { 'hu-HU': 'Budapest Önkéntes Nyugdíjpénztár', 'en-GB': 'Budapest Pension Fund' }, 'urlLabel': 'www.budapestbank.hu', 'url': 'http://www.budapestbank.hu/csoport/nyugdijpenztar' },
        { 'image': 'assets/images/clients/pressenger.png', 'label': { 'hu-HU': 'Pressenger', 'en-GB': 'Pressenger' }, 'urlLabel': 'www.pressenger.com', 'url': 'https://pressenger.com/' },
        { 'image': 'assets/images/clients/sze_e.png', 'label': { 'hu-HU': 'Szegedi Tudományegyetem', 'en-GB': 'University of Szeged' }, 'urlLabel': 'www.u-szeged.hu', 'url': 'http://www.u-szeged.hu/' },
        { 'image': 'assets/images/clients/pe_e.png', 'label': { 'hu-HU': 'Pécsi Tudományegyetem', 'en-GB': 'University of Pécs' }, 'urlLabel': 'pte.hu', 'url': 'http://pte.hu/' },
        { 'image': 'assets/images/clients/deb_e.png', 'label': { 'hu-HU': 'Debreceni Egyetem', 'en-GB': 'University of Debrecen' }, 'urlLabel': 'unideb.hu', 'url': 'https://unideb.hu/' },
        { 'image': 'assets/images/clients/uf_proart.png', 'label': { 'hu-HU': 'ProArt - Szövetség a Szerzői Jogokért', 'en-GB': 'ProArt' }, 'urlLabel': 'proart.hu', 'url': 'http://proart.hu/' },
        { 'image': 'assets/images/clients/nfsz.png', 'label': { 'hu-HU': 'Nemzeti Foglalkoztatási Szolgálat', 'en-GB': 'National Employment Service' }, 'urlLabel': 'nfsz.munka.hu', 'url': 'http://nfsz.munka.hu/' },
        { 'image': 'assets/images/clients/artisjus.png', 'label': { 'hu-HU': 'Artisjus Zenei Alapítvány', 'en-GB': 'Artisjus Music Foundation' }, 'urlLabel': 'www.artisjus.hu', 'url': 'https://www.artisjus.hu/zenei-alapitvany/' },
        { 'image': 'assets/images/clients/uf_diofa.png', 'label': { 'hu-HU': 'Diófa Alapkezelő Zrt.', 'en-GB': 'Diófa Asset Management' }, 'urlLabel': 'www.diofaalapkezelo.hu', 'url': 'https://www.diofaalapkezelo.hu/' }
    ],
    'brandSliderList': [
        // { 'label': 'BSI.ai', 'color': '#363636', 'logo': 'assets/images/brand-slider/logo_bsi.png', 'cover': 'assets/images/brand-slider/cover_bsi.png', 'link': 'http://bsi.ai/', 'text': { 'hu-HU': 'Mesterséges intelligencia kutatás és fejlesztés', 'en-GB': 'Artificial Intelligence Research and Development' } },
        { 'label': 'Creo Recommend', 'color': '#65499d', 'logo': 'assets/images/brand-slider/logo_recommend.png', 'cover': 'assets/images/brand-slider/cover_recommend.png', 'link': 'http://recommender-system.com/', 'text': { 'hu-HU': 'Ismerjük látogatóid ízlését, növeljük az eladásaid mértékét', 'en-GB': 'We know your visitors’ taste, we increase your sales volume' } },
        { 'label': 'Creo Medical', 'color': '#88ac97', 'logo': 'assets/images/brand-slider/logo_medical.png', 'cover': 'assets/images/brand-slider/cover_medical.png', 'link': 'http://medical.creo.hu/', 'text': { 'hu-HU': 'Orvosi műszerek digitális transzformációja', 'en-GB': 'Digital transformation of medical devices' } },
        { 'label': 'Creo Software', 'color': '#f07e19', 'logo': 'assets/images/brand-slider/logo_software.png', 'cover': 'assets/images/brand-slider/cover_software.png', 'link': 'http://szoftverfejlesztes.eu/', 'text': { 'hu-HU': 'Elkészítjük az üzleti szoftvered és segítünk adataid analizálásában', 'en-GB': 'We build your business software and help you with analyzing your valuable data' } },
        { 'label': 'Creo Mobile', 'color': '#d5252b', 'logo': 'assets/images/brand-slider/logo_mobile.png', 'cover': 'assets/images/brand-slider/cover_mobile.png', 'link': 'http://mobile.creo.hu/', 'text': { 'hu-HU': 'Elkészítjük a mobil alkalmazásod különböző platformokon', 'en-GB': 'We develop your mobile app on several platforms' } },
        { 'label': 'HelloCaptcha', 'color': '#031427', 'logo': 'assets/images/brand-slider/logo_hellocaptcha.png', 'cover': 'assets/images/brand-slider/cover_hellocaptcha.png', 'link': 'http://www.hellocaptcha.com/', 'text': { 'hu-HU': 'Egy ingyenes CAPTCHA szolgáltatás, hogy olvasható és szép CAPTCHÁk legyenek az oldaladon', 'en-GB': 'A free CAPTCHA service to replace ugly and unreadable CAPTCHAs' } },
        { 'label': 'Creo Web', 'color': '#161621', 'logo': 'assets/images/brand-slider/logo_web.png', 'cover': 'assets/images/brand-slider/cover_web.png', 'link': 'http://web.creo.hu/', 'text': { 'hu-HU': 'Elkészítjük a weboldalad egyedi technológiánk használatával', 'en-GB': 'We develop your web page with our unique technology' } },
        { 'label': 'PartnerMail', 'color': '#1db4b2', 'logo': 'assets/images/brand-slider/logo_partnermail.png', 'cover': 'assets/images/brand-slider/cover_partnermail.png', 'link': 'http://partnermail.eu/', 'text': { 'hu-HU': 'Személyre szabott ajánlatokat adunk vásárlóidnak egyedi ajánlórendszerünk segítségével', 'en-GB': 'We send personalized offers to your customers with our recommender technology' } },
        // { 'label': 'Creo Recommend', 'color': '#65499d', 'logo': 'assets/images/brand-slider/logo_recommend.png', 'cover': 'assets/images/brand-slider/cover_recommend.png', 'link': 'http://recommender-system.com/', 'download': 'http://recommender-system.com/' },
        // { 'label': 'Creo Software', 'color': '#f07e19', 'logo': 'assets/images/brand-slider/logo_software.png', 'cover': 'assets/images/brand-slider/cover_software.png', 'link': 'http://szoftverfejlesztes.eu/' },
        // { 'label': 'Creo Mobile', 'color': '#d5252b', 'logo': 'assets/images/brand-slider/logo_mobile.png', 'cover': 'assets/images/brand-slider/cover_mobile.png', 'link': 'http://mobile.creo.hu/' },
        // { 'label': 'Creo Illustrate', 'color': '#a461aa', 'logo': 'assets/images/brand-slider/logo_illustrate.png', 'cover': 'assets/images/brand-slider/cover_illustrate.png', 'link': 'http://illustrate.creo.hu/' },
        // { 'label': 'Creo Facebook', 'color': '#214f8f', 'logo': 'assets/images/brand-slider/logo_facebook.png', 'cover': 'assets/images/brand-slider/cover_facebook.png', 'link': 'http://facebook.creo.hu/' },
        // { 'label': 'Creo Webposition', 'color': '#25ae80', 'logo': 'assets/images/brand-slider/logo_webposition.png', 'cover': 'assets/images/brand-slider/cover_webposition.png', 'link': 'http://webposition.hu/' },
        // { 'label': 'Creo Web', 'color': '#161621', 'logo': 'assets/images/brand-slider/logo_web.png', 'cover': 'assets/images/brand-slider/cover_web.png', 'link': 'http://interactive.hu/' },
        // { 'label': 'HelloCaptcha', 'color': '#031427', 'logo': 'assets/images/brand-slider/logo_hellocaptcha.png', 'cover': 'assets/images/brand-slider/cover_hellocaptcha.png', 'link': 'http://www.hellocaptcha.com/' },
        // { 'label': 'PartnerMail', 'color': '#1db4b2', 'logo': 'assets/images/brand-slider/logo_partnermail.png', 'cover': 'assets/images/brand-slider/cover_partnermail.png', 'link': 'http://partnermail.eu/' }
    ],
}

export default globalConfig;