import HeroRow from 'components/rows/www/HeroRow.js';
import SliderRow from 'components/rows/www/SliderRow.js';
import ClientRow from 'components/rows/www/ClientRow.js';
import ContactRow from 'components/rows/www/ContactRow.js';

const siteConfig = {
    'WWW': {
        'title': 'creo.hu Kft.',
        'logo': 'assets/images/sites/www/logo.png',
        'availableLanguages': ['hu-HU', 'en-GB'],
        'color': {
            'bg': 'bg-slate-700',
            'bg-hover': 'hover:bg-slate-700',
            'text': 'text-slate-700',
            'text-hover': 'hover:text-slate-700',
            'border': 'border-slate-700',
            'border-hover': 'hover:border-slate-700',
        },
        'rowList': [
            { 'id': 'hero', 'component': HeroRow },
            { 'id': 'slider', 'component': SliderRow },
            { 'id': 'references', 'component': ClientRow },
            { 'id': 'contact', 'component': ContactRow }
        ]
    }
}

export default siteConfig;