import React, { useState } from 'react';
import * as Icons from 'react-icons/fa';
import { motion } from 'framer-motion';

import { getLanguage, getTranslation } from 'utils/DictionaryUtils';

import globalConfig from 'config/globalConfig.js';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper';

export default function SliderRow(props) {
    // const pagination = {
    //     clickable: true,
    //     renderBullet: function (index, className) {
    //         return '<span class='' + className + ''>' + (index + 1) + '</span>';
    //     },
    // };

    return (
        <div className='relative w-full flex flex-col gap-8 py-16 bg-gradient-to-b from-white to-[#4fa7d8] overflow-hidden'>

            <div className='flex flex-col gap-2 text-center text-slate-900 site-px'>
                <div className='text-4xl font-extrabold uppercase'>{getTranslation('www', 'slider-title')}</div>
                <div className='text-lg font-light'>{getTranslation('www', 'slider-sub-title')}</div>
            </div>

            <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={15}
                loop={true}
                pagination={{
                    clickable: true,
                    // renderBullet: function (index, className) {
                    //     // return ('<span class='' + className + ''>' + (index + 1) + '</span>');
                    //     return (<div>{index}</div>);
                    // },
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Pagination]}
                style={{ width: '100%', overflow: 'visible' }}>
                {globalConfig.brandSliderList.map((item, index) => (<SwiperSlide key={index} style={{ width: '245px' }}><SliderItem data={item} /></SwiperSlide>))}
            </Swiper>

        </div>
    );
}

function SliderItem(props) {
    const [open, setOpen] = useState(false);

    return (
        <div className='w-[245px] h-[290px] relative  rounded-xl shadow-xl overflow-hidden' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>

            <motion.div
                className='absolute w-full h-full flex items-end justify-center p-6 rounded-xl bg-cover z-10'
                style={{ backgroundColor: props.data.color, backgroundImage: 'url(' + props.data.cover + ')' }}
                initial='closed'
                animate={open ? 'open' : 'closed'}
                transition={{ opacity: { ease: 'linear' }, layout: { duration: 0.3 } }}
                variants={{
                    open: { scale: 1.5, opacity: 0, transitionEnd: { display: 'none' } },
                    closed: { scale: 1, opacity: 1, display: 'flex' },
                    // open: { translateY: '-100%', },
                    // closed: { translateY: 0, },
                }}>

                <div className='flex flex-col items-center gap-1 text-white'>
                    <div className='font-extrabold text-lg uppercase'>{props.data.label}</div>
                    <Icons.FaChevronDown className='text-xl' />
                </div>

            </motion.div>

            <motion.div
                className='w-full h-full flex p-6 bg-white rounded-xl'
                initial='closed'
                animate={open ? 'open' : 'closed'}
                variants={{
                    open: { scale: 1, },
                    closed: { scale: 0.5, },
                }}>

                <div className='w-full flex flex-col items-center'>

                    <div className='w-full flex-1 flex items-center justify-center'>
                        <img src={props.data.logo} alt={props.data.label} />
                    </div>

                    <div className='w-full flex-1 flex items-center justify-center'>
                        <div className='text-center text-sm'>{props.data.text[getLanguage()]}</div>
                    </div>

                    <div className='w-full flex-1 flex items-end'>
                        <a href={props.data.link} target='_blank' className='w-full text-white group p-4 rounded-lg text-sm font-extrabold text-center overflow-hidden transition hover:scale-95' rel='noreferrer' style={{ backgroundColor: props.data.color }}>
                            {props.data.label}
                        </a>
                    </div>

                </div>

            </motion.div>

        </div>
    );
}