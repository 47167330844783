import * as Icons from 'react-icons/fa';

import { IconLink } from 'components/common/Common';

import globalConfig from 'config/globalConfig.js';

export default function Footer(props) {
	return (
		<div className={'bg-white ' + (props.className || '')}>

			<div className='site-px py-8 flex flex-col gap-6 md:gap-0 md:flex-row justify-between items-center'>
				<div className='flex flex-col sm:flex-row gap-2 sm:gap-4 items-center text-sm leading-none'>
					<img src='assets/images/creo-dots.svg' alt='Creo Group' className='w-14' />
					<div>{globalConfig.contact.name} © 2016-{new Date().getFullYear()}</div>
				</div>

				<div className='flex flex-col items-center sm:flex-row sm:items-start gap-4 sm:gap-6'>
					<IconLink icon={<Icons.FaGlobeAfrica />} value={globalConfig.contact.web} href={'https://' + globalConfig.contact.web + '/'} />
					<IconLink icon={<Icons.FaEnvelope />} value={globalConfig.contact.email} href={'mailto:' + globalConfig.contact.email} />
					<IconLink icon={<Icons.FaMobileAlt />} value={globalConfig.contact.phone} href={'tel:' + globalConfig.contact.phone} />
				</div>
			</div>

		</div>
	);
}