import React from 'react';
import Header from 'components/common/Header.js';
import Footer from 'components/common/Footer.js';

import { getSiteConfig, setSiteKey } from 'utils/SiteUtils.js';
import { setLanguage } from 'utils/DictionaryUtils.js';

import globalConfig from 'config/globalConfig.js'

export default function OnePageCMS(props) {
    const setupLanguage = () => {
        const availableLanguages = getSiteConfig().availableLanguages;
        const navigatorLanguage = navigator.language || navigator.userLanguage;
        const defaultLanguage = availableLanguages.indexOf(navigatorLanguage) > -1 ? navigatorLanguage : availableLanguages[0];
        const storedLanguage = localStorage.getItem(globalConfig.etc.cookiePrefix + 'language');
        const siteLanguage = (storedLanguage && availableLanguages.indexOf(storedLanguage) > -1) ? storedLanguage : defaultLanguage;

        // console.log('availableLanguages: ' + availableLanguages);
        // console.log('navigatorLanguage: ' + navigatorLanguage);
        // console.log('defaultLanguage: ' + defaultLanguage);
        // console.log('storedLanguage: ' + storedLanguage);
        // console.log('siteLanguage: ' + siteLanguage);

        setLanguage(siteLanguage);
    };

    setSiteKey(props.siteKey);
    setupLanguage();

    if (props.siteKey) {
        return (
            <div className={'w-full flex flex-col justify-between ' + (props.className || '')}>

                <Header />

                <div className='flex flex-col min-h-screen z-10 shadow-xl'>
                    {getSiteConfig().rowList.map((item, index) => (<item.component key={item.id} />))}
                </div>

                <Footer className='w-full sticky top-0 bottom-0 left-0' />

            </div>
        )
    } else {
        return (
            <div className={'w-full min-h-screen bg-sky-800 flex flex-col justify-between ' + (props.className || '')}>


            </div>
        )
    }

}