import dictionaryConfig from '../config/dictionaryConfig.js';
import globalConfig from '../config/globalConfig.js';

let language;

export const setLanguage = (_language) => {
    language = _language;
};

export const getLanguage = () => {
    return language;
};

export const storeLanguage = (language, refresh) => {
    localStorage.setItem(globalConfig.etc.cookiePrefix + 'language', language);
    if (refresh)
        window.location.reload();
};

export const getTranslation = (topic, key) => {
    return _getTranslation(getLanguage(), topic, key, false);
};

export const getTranslationWithDefault = (topic, key, def) => {
    return _getTranslation(getLanguage(), topic, key, def);
};

export const getTranslationForLanguageWithDefault = (lang, topic, key, def) => {
    return _getTranslation(lang, topic, key, def);
};

const _getTranslation = (lang, topic, key, def) => {
    let translation = def ? def : ('[!' + key + '!]');
    if (dictionaryConfig[topic] && dictionaryConfig[topic][key] && dictionaryConfig[topic][key][lang])
        translation = dictionaryConfig[topic][key][lang];
    return translation;
};