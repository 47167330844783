import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

import { getTranslation, getLanguage } from 'utils/DictionaryUtils';

export default function HeroRow(props) {
    const image = getLanguage() === 'hu-HU' ? 'assets/images/sites/www/hero/ballon_hu.png' : 'assets/images/sites/www/hero/ballon_en.png';

    return (
        <div className='relative relativew-full flex items-center justify-center min-h-[560px] bg-[#cdedf7] overflow-hidden'>

            <ImageBackround />

            <div className='flex flex-col md:flex-row items-center gap-16 pb-32 md:pb-20 pt-36 site-px z-10'>

                <div className='w-[240px] h-[274px] shrink md:shrink-0 order-last md:order-first relative'>
                    <img className='w-[240px] h-[274px] absolute animate-levitate' src={image} alt='' />
                </div>

                <div className='flex flex-col items-center md:items-start gap-2 text-slate-900 text-center md:text-justify max-w-2xl bg-white bg-opacity-50 backdrop-blur-sm rounded-xl shadow-xl p-6'>
                    <img src='assets/images/creo-dots.svg' alt='Creo Group' className='w-14' />
                    <div className='text-4xl font-extrabold uppercase pb-2'>{getTranslation('www', 'hero-title')}</div>
                    <div className='leading-6 '>{getTranslation('www', 'hero-content')}</div>
                </div>

            </div>

        </div >
    );
}

function ImageBackround(props) {
    return (
        <div className='absolute w-full h-full'>
            <ParallaxBanner
                className='relative w-full h-full'
                layers={[
                    {
                        speed: -25,
                        children: (<>
                            <img className='absolute top-[370px] left-[260px] lg:left-[180px] min-w-[193px] h-[53px]' src='assets/images/sites/www/hero/top_cloud_left.png' alt='' />
                            <img className='absolute top-[450px] lg:top-[310px] right-[80px] min-w-[323px] h-[89px]' src='assets/images/sites/www/hero/top_cloud_right.png' alt='' />
                            <img className='absolute bottom-[340px] lg:bottom-[310px] right-[140px] min-w-[580px] h-[233px]' src='assets/images/sites/www/hero/bottom_cloud_right.png' alt='' />
                        </>),
                    },
                    {
                        speed: -15,
                        children: (<>
                            <img className='hidden lg:block absolute bottom-[170px] left-0 lg:-left-[430px] min-w-[898px] h-[316px] -scale-x-100' src='assets/images/sites/www/hero/mountain_left.png' alt='' />
                            <img className='absolute bottom-[150px] lg:bottom-[170px] -right-[250px] lg:-right-[260px] min-w-[745px] h-[265px]' src='assets/images/sites/www/hero/mountain_right.png' alt='' />
                        </>),
                    },
                ]} />
            <img className='absolute bottom-0 left-0 min-w-[2196px] h-[60px]' src='assets/images/sites/www/hero/bottom.png' alt='' />
        </div>
    );
}

// function ImageBackround(props) {
//     return (
//         <div className='absolute w-full h-full'>
//             <div className='relative w-full h-full bg-red-400'>
//                 <Parallax className='absolute top-[120px] right-[100px] ' speed={-20} ><img className='min-w-[323px] h-[89px]' src='assets/images/sites/www/hero/top_cloud_right.png' alt='' /></Parallax>
//                 <Parallax className='absolute top-[160px] left-[180px]' speed={-20} ><img className='min-w-[193px] h-[53px]' src='assets/images/sites/www/hero/top_cloud_left.png' alt='' /></Parallax>
//                 <Parallax className='absolute bottom-0 right-[220px]' speed={-20}><img className='min-w-[580px] h-[233px]' src='assets/images/sites/www/hero/bottom_cloud_right.png' alt='' /></Parallax>

//                 <Parallax className='absolute bottom-0 left-0 lg:-left-[430px]' speed={-15} ><img className='min-w-[898px] h-[316px] -scale-x-100' src='assets/images/sites/www/hero/mountain_left.png' alt='' /></Parallax>
//                 <Parallax className='absolute bottom-0 right-0 lg:-right-[260px]' speed={-15}><img className='min-w-[745px] h-[265px]' src='assets/images/sites/www/hero/mountain_right.png' alt='' /></Parallax>

//                 <img className='absolute bottom-0 left-0 min-w-[2196px] h-[60px]' src='assets/images/sites/www/hero/bottom.png' alt='' />
//             </div>
//         </div>
//     );
// }