import siteConfig from 'config/siteConfig';

let siteKey = 'WWW';

export const setSiteKey = (key) => {
    siteKey = key;
};

export const getSiteKey = () => {
    return siteKey;
};

export const getSiteConfig = () => {
    return siteConfig[siteKey];
};
