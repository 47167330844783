import React, { useState, useEffect, useRef } from 'react';

import { getFlipHover } from 'utils/GenericUtils';
import { getTranslation, getLanguage } from 'utils/DictionaryUtils';

import globalConfig from 'config/globalConfig.js';

export default function ClientRow(props) {
    return (
        <div className='relative w-full flex flex-col gap-8 sm:gap-16 items-center bg-[#4fa7d8] py-16 site-px'>

            <div className='flex flex-col gap-2 items-center text-center site-px'>
                <img src='assets/images/creo-dots.svg' alt='Creo Group' className='w-14' />

                <div className='hidden md:block text-4xl font-extrabold uppercase'>{getTranslation('www', 'client-title') + ', ' + getTranslation('www', 'client-sub-title')}</div>

                <div className='flex md:hidden flex-col gap-2 text-center text-slate-900'>
                    <div className='text-4xl font-extrabold uppercase'>{getTranslation('www', 'client-title')}</div>
                    <div className='text-lg font-light'>{getTranslation('www', 'client-sub-title') + ' 😊'}</div>
                </div>

            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 max-w-boxed'>
                {globalConfig.referencesList.map((item, index) => (<ClientItem key={index} data={item} />))}
            </div>

        </div>
    );
}

function ClientItem(props) {
    const [hover, setHover] = useState(null);

    const container = useRef();

    const animationDuration = 300;

    useEffect(() => {
        const to = setTimeout(() => { if (hover && hover.startsWith('out-')) setHover(null) }, animationDuration);
        return () => { clearTimeout(to) };
    }, [hover])

    const _setHover = (e, show) => { if (props.data) setHover(getFlipHover(container, e, show)); }


    return (
        <a style={{ perspective: 1500 }} href={props.data.url} target='_blank' rel='noreferrer' ref={container} className='relative flex items-center justify-center bg-white rounded-xl shadow-xl aspect-square overflow-hidden' onMouseEnter={e => _setHover(e, true)} onMouseLeave={e => _setHover(e, false)}>

            <img className='p-4' src={props.data.image} alt={props.data.name} />

            <div className={'absolute top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center rounded-xl transform ' + (hover ? hover : 'opacity-0')} >

                <div className='flex flex-col gap-2 items-center justify-center text-center p-6'>
                    <div className='text-white font-extrabold text-sm sm:text-base lg:text-xl'>{props.data.label[getLanguage()]}</div>
                    <div className='text-white text-sm text-center'>{props.data.urlLabel}</div>
                </div>

            </div>

        </a>
    );
}